@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

//Set up variables
$font: 'Roboto Condensed', sans-serif;
$offBlack: #2d2d2d;
$offWhite: #f5f5f5;

@mixin title-font {
  $font: $font;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: $offWhite;
  font-weight: 200;
  margin-right: 15px;
}

//Customise theme colors
$theme-colors: (
  'primary': #0074d9,
  'danger': #ff4136,
  'dark': $offBlack,
);

$brand-dark: #2d2d2d;

//Custom Bootstrap
.navbar {
  background: $offBlack;
  color: $offWhite;
}

.dark {
  background: $offBlack;
}

.dark-text {
  @include title-font();
  color: $offBlack;
}

.dark-text-p {
  color: $offBlack;
  font-weight: 300;
}

.light-text {
  @include title-font();
  font-size: 12px;
}

.light-text-title {
  @include title-font();
  font-size: 20px;
  margin-top: -50px;
  margin-bottom: 30px;
}

.nav-item {
  @include title-font();
}

.social-icon {
  font-size: 24px;
  margin-top: -2px;
}

.bg-grey {
  background: $offBlack;
}

//My styles
body {
  font-family: $font;
  overflow-x: hidden;
  position: relative;
  padding-top: 50px;
  background: $offBlack;
  color: $offWhite;
}

html {
  overflow-x: hidden;
}

.mm-logo {
  width: 500px;
}

.nav-logo-img {
  height: 35px;
}

.navbar-text {
  color: $offBlack;
}

.navbar-text a {
  color: $offBlack;
}

.video-card {
  border: 0;
}

.nav-link:hover {
  cursor: pointer;
}

.offer-container {
  margin-bottom: 120px;
}

.top-video {
  margin-top: 0px;
  width: 110%;
  object-fit: cover;
  height: 550px;
}

.highlight-card,
.modal-bg {
  background: $offWhite;
}

.highlight-card:hover {
  -webkit-animation: pulsate-fwd 0.8s ease-in-out both;
  animation: pulsate-fwd 0.8s ease-in-out both;
}

.btn-gry:hover {
  opacity: 0.9;
  color: $offWhite;
}

.blockquote {
  font-weight: 300;
  color: $offWhite;
}

.page-footer {
  margin-top: 80px;
}

.carousel-inner {
  height: 30vh;
  font: $font;
  color: $offWhite;
}
.carousel-caption {
  color: $offWhite;
  top: 50%;
}

.carousel-caption h3 {
  @include title-font();
  font-size: 16px;
  text-transform: unset;
}

.carousel-caption p {
  @include title-font();
  font-size: 14px;
  opacity: 0.9;
  margin-top: 15px;
}

.jumbo-video {
  padding: 0;
  background: $offBlack;
  margin-bottom: 130px;
}

.jumbo-video video {
  width: 100%;
}

.jumbo-video p {
  @include title-font();
  font-size: 20px;
  padding-right: 200px;
  margin-top: 20px;
  text-align: center;

  position: absolute;
  width: 100%;
}

form label {
  @include title-font();
  font-size: 12px;
}

form input {
  letter-spacing: 2px;
}

.btn-light {
  @include title-font();
  color: $offBlack;
  margin-right: 0px;
}
.footer-logo {
  width: 30%;
}

.navbar-text {
  color: $offBlack;
}

.dark-text-p a {
  color: $offBlack;
  text-decoration: underline;
}

.blockquote {
  background: $offWhite;
  color: $offBlack;
  padding: 10px;
  border-radius: 1%;
  opacity: 0.9;
}

#success-message {
  background: $offWhite;
  color: green;
  border: 3px solid green;
  margin: 7px 1px 1px 1px;
  padding: 2px;
  text-align: center;
  display: none;
}

#error-message {
  background: $offWhite;
  color: red;
  border: 3px solid red;
  margin: 7px 1px 1px 1px;
  padding: 2px;
  text-align: center;
  display: none;
}

#top-quote {
  font-weight: 600;
  text-transform: capitalize;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-28 16:15:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

//Media rules
@media only screen and (max-width: 500px) {
  .top-video {
    margin-top: 0px;
    margin-left: 0px;
    object-fit: cover;
    height: 250px;
  }

  .mm-logo {
    width: 250px;
  }

  .highlight-card {
    margin: 10px;
  }

  .carousel-inner {
    height: 70vh;
    font: $font;
    color: $offWhite;
  }

  .jumbo-video p {
    text-align: left;
    padding-left: 3px;
    background: $offBlack;
    font-size: 11px;
    opacity: 0.8;
    width: 100%;
  }

  .footer-logo {
    width: 70%;
    margin: 15px;
  }

  .mobile-padding {
    padding: 20px;
  }
}
